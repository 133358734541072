import { Router, BrowserRouter as Switch, Route, Routes, BrowserRouter } from 'react-router-dom';

import './App.css';
import './components/css/bootstrap.min.css';
import './components/css/estilos.css'
import './components/css/style.css'
import './components/css/css2.css'
import './components/css/all.css'
import './components/css/src.css'

import Navbar from './components/js/navbar.js';
import Footer from './components/js/footer.js';
import Cover from './components/js/cover.js';




function App() {
  return (
    <BrowserRouter>
    <Navbar/>
      <Routes>
        <Route exact path="/" element={<Cover/>} />
        <Route exact path="/certificados/iems" element={<Cover/>} />
      </Routes>
      
    <Footer/>
    </BrowserRouter>
  );
}

export default App;
