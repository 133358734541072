import React, {useEffect, useState} from "react";
import datos from './datos.json'

const lote = window.location.pathname.substring(window.location.pathname.length);
console.log(window.location.pathname)
console.log(lote)


export default function Cover() {


    const url = document.URL
    const urlm = document.URL.length - 8
    const urlg = url.substring(urlm)

    var myDate = new Date('2020/7/17');
    

    for(let i = 0; i < datos.length; i++){
      if(datos[i].id == urlg){
        return (
      
          <div>
            <div class="header">
              <div class="container h-100">
                <div class="row h-100 align-items-center justify-content-center">
                  <div class="col-8">
                    <h1 style={{fontSize: "36px"}}>Certificado de Terminación de Estudios</h1>
                  </div>
                </div>
              </div>
            </div>
    
    
    
            <section id="detalle-folio">
              <div class="container">
                <div class="row col-md-8 offset-md-2">
                  <div class="panel">
                    <table class="table">
                      <tr>
                        <td class="notification is-info is-light title is-5" colspan="2" style={{borderStyle:"none", padding:"8px"}}> 
                          Datos del estudiante
                        </td>
                      </tr>
                      <tr className="lineagris2" style={{padding:"8px !important"}}>
                        <td style={{padding:"10px 10px 10px 8px "}}><b className="lineagris">Nombre y apellidos:</b></td>
                        <td style={{textTransform:"uppercase"}}>{datos[i].nombre}</td>
                      </tr>
                      <tr className="lineagris2">
                        <td style={{padding:"10px 10px 10px 8px "}}><b className="lineagris">Matrícula:</b></td>
                        <td>{datos[i].matricula}</td>
                      </tr>
                      
                      <tr>
                        <td class="notification is-info is-light title is-5" colspan="2" style={{borderStyle:"none", padding:"8px"}}> 
                          Datos del plantel o servicio educativo
                        </td>
                      </tr>
                      <tr className="lineagris2">
                        <td style={{padding:"10px 15px 10px 8px "}}><b className="lineagris">Institución educativa emisora:</b></td>
                        <td>Servicio Nacional de Bachillerato en Línea, "Prepa en Línea-SEP"</td>
                      </tr>
                      <tr className="lineagris2">
                        <td style={{padding:"10px 15px 10px 8px "}}><b className="lineagris">Plantel o servicio educativo:</b></td>
                        <td>Servicio Nacional de Bachillerato en Línea, "Prepa en Línea-SEP"</td>
                      </tr>
                      <tr className="lineagris2">
                        <td style={{padding:"10px 15px 10px 8px "}}><b className="lineagris">Clave de Centro de Trabajo:</b></td>
                        <td>09DBH0001D</td>
                      </tr>
                      
                      <tr>
                        <td class="notification is-info is-light title is-5" colspan="2" style={{borderStyle:"none", padding:"8px"}}> 
                          Trayectoria académica y datos del documento
                        </td>
                      </tr>
                      <tr className="lineagris2">
                        <td style={{padding:"10px 15px 10px 8px "}}><b className="lineagris">Plan de estudios:</b></td>
                        <td>Bachillerato, con formación básica para el trabajo</td>
                      </tr>
                      <tr className="lineagris2">
                        <td style={{padding:"10px 15px 10px 8px "}}><b className="lineagris">Promedio:</b></td>
                        <td>{datos[i].promedio} {datos[i].proletra}</td>
                      </tr>
                      <tr className="lineagris2">
                        <td style={{padding:"10px 15px 10px 8px "}}><b className="lineagris">Créditos obtenidos:</b></td>
                        <td>276 de un total de 276</td>
                      </tr>
                      <tr className="lineagris2">
                        <td style={{padding:"10px 15px 10px 8px "}}><b className="lineagris">Periodo de estudios:</b></td>
                        <td>{datos[i].pinip} al {datos[i].pfinalp}</td>
                      </tr>
                      <tr className="lineagris2">
                        <td style={{padding:"10px 15px 10px 8px "}}><b className="lineagris">Tipo de documento:</b></td>
                        <td>Certificado de Terminación de Estudios</td>
                      </tr>
                      <tr className="lineagris2">
                        <td style={{padding:"10px 15px 10px 8px "}}><b className="lineagris">Estatus:</b></td>
                        <td> <span class="tag is-success" style={{padding:"0"}}>Registrado en el SIGED</span></td>
                      </tr>
                      <tr className="lineagris2">
                        <td style={{padding:"10px 15px 10px 8px "}}><b className="lineagris">Folio:</b></td>
                        <td>{datos[i].folio}</td>
                      </tr>
                      <tr >
                        <td style={{padding:"10px 15px 10px 8px "}}><b className="lineagris">Fecha y hora de timbrado:</b></td>
                        <td>{datos[i].ftimbradol} a las {datos[i].fhora}</td>
                      </tr>
                    </table><pre class="center text ok"><h4>Documento Validado.</h4></pre>
                    <div class="row text">
                      <div class="col align-self-center">
                        <a href={"https://siged.sep.gob.mx.busqueda.info/certificados/iems/?"+datos[i].folio}>
                        <button class="btn tag is-success ok">
                          Validación con SIGED
                        </button> 
                        </a>
                        
                      </div>
                    </div>
                    <div class="hr"></div>
                  </div>
                </div>
              </div>
            </section>
    
          </div>
        );
      }
      }


    

}