import React, { useState } from 'react';

export default function Navbar(){


    const [count, setCount] = useState('none')
    function collapsenavbar(){
        if(count == '' || 'none'){
            document.getElementById('navbarNav').style.display = "inherit"
            setCount('inherit')
        }
        if(count == "inherit"){
            document.getElementById('navbarNav').style.display = "none"
            setCount('none')
        }
    }

    const [count2, setCount2] = useState('false')
    function collapsesub1(x){
        console.log(x)
        if(count2 == 'true'){
            document.getElementById('submenu1').style.display = "none"
            document.getElementById('submenu2').style.display = "none"
            document.getElementById('submenu3').style.display = "none"
            document.getElementById('submenu4').style.display = "none"
            document.getElementById('submenu5').style.display = "none"
            setCount2('false')
            

        }
        if(count2 == 'false'){

            if(x == 'conocenos'){
                document.getElementById('submenu1').style.display = "inherit"
                setCount2('true')
            }
            if(x == 'perfiles'){
                document.getElementById('submenu2').style.display = "inherit"
                setCount2('true')
            }
            if(x == 'programa'){
                document.getElementById('submenu3').style.display = "inherit"
                setCount2('true')
            }
            if(x == 'transparencia'){
                document.getElementById('submenu4').style.display = "inherit"
                setCount2('true')
            }
            if(x == 'registro'){
                document.getElementById('submenu5').style.display = "inherit"
                setCount2('true')
            }

        }

        

        
    }


    return(
        <div className="App">
        
        <div class="gobierno" style={{backgroundColor:"#0b231e", paddingTop:"10px", paddingBottom:"10px"}}>
            <div style={{width:"100%", maxWidth:"1600px", margin:"0 auto"}}>
                <nav class="navbar navbar-expand-lg navbar-dark">
                    <div class="container-fluid">
                        <a class="navbar-brand" href="https://www.gob.mx/">
                            <img src="https://framework-gb.cdn.gob.mx/landing/img/logoheader.svg"
                                alt="logo gobierno de méxico" width="128px"/>
                        </a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation" data-parent="accordian-6">
                            <span class="navbar-toggler-icon"></span>
                        </button>

                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav ms-auto">
                                <li class="nav-item">
                                    <a class="nav-link" href="https://www.gob.mx/tramites" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Trámites
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="https://www.gob.mx/gobierno" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Gobierno
                                    </a>
                                </li>
                                <li class="nav-item pt-2">
                                    <a class="text-white" href="https://www.gob.mx/busqueda?utf8=%e2%9c%93"><i
                                            class="fas fa-search"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </nav>
            </div>
        </div>

        <div class="main-menu">
            <nav class="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
                <div class="container-fluid">
                    <a class="navbar-brand" href="https://prepaenlinea.sep.gob.mx/">
                        <i class="glyphicon fa-home fas"></i>
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={() => collapsenavbar(count)}>
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNav" >
                        <ul class="navbar-nav resp1" style={{alignItems:"flex-start", width:"100%"}}>

                            <li class="nav-item dropdown submenuNav">
                                <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button"
                                    aria-expanded="false" onClick={() => collapsesub1("conocenos")}>Conocenos</a>
                                <ul class="dropdown-menu  bg-dark" style={{width:"100%"}} id="submenu1">
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/conocenos/conoce-prepa-en-linea-sep/">Conoce
                                            Prepa en
                                            Línea-SEP</a></li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/conocenos/que-es-estudiar-en-linea/">¿Qué
                                            es estudiar en
                                            línea?</a></li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/conocenos/modelo-educativo/">Modelo
                                            educativo</a></li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/conocenos/requerimientos-tecnologicos/">Requerimientos
                                            tecnológicos</a></li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/conocenos/estructura/">Estructura</a>
                                    </li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/conocenos/preguntas-frecuentes/">Preguntas
                                            frecuentes</a></li>

                                </ul>
                            </li>

                            <li class="nav-item dropdown submenuNav" id="submenu2" >
                                <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#/" role="button"
                                    aria-expanded="false" onClick={() => collapsesub1("perfiles")}>Perfiles de la
                                    comunidad</a>
                                <ul class="dropdown-menu  bg-dark"  >
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/perfiles-de-la-comunidad/aspirantes/">Aspirantes</a>
                                    </li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/perfiles-de-la-comunidad/estudiantes/">Estudiantes</a>
                                    </li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/perfiles-de-la-comunidad/asesores-virtuales/#">Asesores
                                            virtuales</a></li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/perfiles-de-la-comunidad/tutores/">Tutores</a>
                                    </li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/perfiles-de-la-comunidad/egresados/">Egresados</a>
                                    </li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/perfiles-de-la-comunidad/supervisores-de-aseguramiento-de-la-calidad-sac/">Supervisores
                                            para el Acompañamiento y la Mejora Educativa
                                            SAME </a>
                                    </li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/perfiles-de-la-comunidad/equipo-administrativo/">Equipo
                                            administrativo</a></li>
                                </ul>
                            </li>

                            <li class="nav-item dropdown submenuNav">
                                <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button"
                                    aria-expanded="false" onClick={() => collapsesub1("programa")}>Nuestro programa</a>
                                <ul class="dropdown-menu  bg-dark" id="submenu3">
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/nuestro-programa/plan-de-estudios/">Plan
                                            de estudios</a></li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/nuestro-programa/plan-de-estudios/perfil-de-ingreso/">Perfil
                                            de
                                            ingreso</a></li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/nuestro-programa/plan-de-estudios/competencias/">Competencias</a>
                                    </li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/nuestro-programa/plan-de-estudios/reingreso-revalidacion-y-equivalencia-de-estudios/">Reingreso,
                                            revalidación y equivalencia de estudios</a></li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/nuestro-programa/mapa-curricular/">Mapa
                                            curricular</a></li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/nuestro-programa/trayectoria/">Trayectoria</a>
                                    </li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/nuestro-programa/certificado-electronico/">Certificado
                                            electrónico</a></li>
                                </ul>
                            </li>

                            <li class="nav-item submenuNav">
                                <a class="nav-link"
                                    href="https://prepaenlinea.sep.gob.mx/regularizacion/">Regularización</a>
                            </li>

                            <li class="nav-item dropdown submenuNav">
                                <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button"
                                    aria-expanded="false" onClick={() => collapsesub1("transparencia")}>Transparencia</a>
                                <ul class="dropdown-menu  bg-dark" id="submenu4">
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/transparencia/acuerdos-y-normas/">Acuerdos
                                            y normas</a></li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/transparencia/numeralia/">Numeralia</a>
                                    </li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/transparencia/aviso-de-privacidad/">Avisos
                                            de privacidad</a>
                                    </li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/transparencia/circulares/">Circulares</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item submenuNav">
                                <a class="nav-link resp2" href="https://prepaenlinea.sep.gob.mx/directorio-de-atencion/">Dirección
                                    de
                                    atención</a>
                            </li>

                            <li class="nav-item dropdown submenuNav">
                                <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button"
                                    aria-expanded="false" onClick={() => collapsesub1("registro")}>Registro</a>
                                <ul class="dropdown-menu  bg-dark" id="submenu5">
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/registro/registro-de-aspirante-post-convocatoria-2021-4/">Registro
                                            de aspirantes</a></li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/registro/registro-de-asesor-virtual/">Registro
                                            de asesor
                                            virtual</a></li>
                                    <li><a class="dropdown-item"
                                            href="https://prepaenlinea.sep.gob.mx/registro/registro-de-tutora/">Registro
                                            de tutor(a)</a></li>

                                </ul>
                            </li>
                            <li class="nav-item">
                                <a class="fusion-main-menu-icon fusion-bar-highlight" href="#" aria-label="Buscar"
                                    data-title="Buscar" title="Buscar"></a>
                                <a class="nav-link" href="https://prepaenlinea.sep.gob.mx/?s"><i
                                        class="fas fa-search"></i></a>
                            </li>
                        </ul>

                        <a class="ms-auto"
                            href="https://prepaenlinea.sep.gob.mx/mi-comunidad-de-aprendizaje/mi-comunidad/"><img
                                src="https://prepaenlinea.sep.gob.mx/wp-content/uploads/2020/03/btn_02-mi-comunidad-especial.png"
                                width="198" class="boton_comunidad"/></a>
                    </div>
                </div>
            </nav>
        </div>


        <script src="https://prepaenlinea.sep.gob.mx/validacion/public/js/bootstrap.bundle.min.js" crossorigin="anonymous"> </script>
      </div>
    )
}