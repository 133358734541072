import React from "react";
import facebook from './img/facebook.png'
import twitter from './img/twitter.png'





export default function Cover() {
    return (
      <div className="footer">
        <div class="footer_gobierno">
        <div class="container-fluid">
            <div data-v-41055fb8="" style={{width:"100%", maxWidth:"1600px", margin: "0 auto"}}>
                <div data-v-41055fb8="" class="row">
                    <div data-v-41055fb8="" class="col-sm-6 col-lg-3">
                        <img data-v-41055fb8="" src="https://framework-gb.cdn.gob.mx/landing/img/logofooter.svg"
                            href="https://prepaenlinea.sep.gob.mx/" alt="logo gobierno de méxico" class="logo_footer"
                            style={{width:"100%", maxWidth:"200px"}}/>
                    </div>
                    <div data-v-41055fb8="" class="col-sm-6 col-lg-3">
                        <h6 data-v-41055fb8="" align="left" id="division" data-fontsize="16" data-lineheight="19.2px"
                            class="fusion-responsive-typography-calculated mb-5"
                            style={{fontSize:"16", lineHeight: "1.2", minFontSize:"16"}}>
                            Enlaces</h6>
                        <ul data-v-41055fb8="" class="list-unstyled">
                            <li data-v-41055fb8="">
                                <a data-v-41055fb8="" href="http://www.participa.gob.mx/" target="_blank">Participa</a>
                            </li>
                            <li data-v-41055fb8="">
                                <a data-v-41055fb8="" href="https://www.gob.mx/publicaciones"
                                    target="_blank">Publicaciones
                                    Oficiales</a>
                            </li>
                            <li data-v-41055fb8="">
                                <a data-v-41055fb8="" href="http://www.ordenjuridico.gob.mx/" target="_blank">Marco
                                    Jurídico</a>
                            </li>
                            <li data-v-41055fb8="">
                                <a data-v-41055fb8="" href="https://consultapublicamx.inai.org.mx/vut-web/"
                                    target="_blank">Plataforma
                                    Nacional de Transparencia</a>
                            </li>
                        </ul>
                    </div>
                    <div data-v-41055fb8="" class="col-sm-6 col-lg-3">
                        <h6 data-v-41055fb8="" align="left" id="division" data-fontsize="16" data-lineheight="19.2px"
                            class="fusion-responsive-typography-calculated mb-5"
                            style={{fontSize:"16", lineHeight: "1.2", minFontSize:"16"}}>
                            ¿Qué es gob.mx?</h6>
                        <p data-v-41055fb8="">Es el portal único de trámites, información y participación ciudadana. <a
                                data-v-41055fb8="" href="https://www.gob.mx/que-es-gobmx"><u data-v-41055fb8="">Leer
                                    más</u></a></p>
                        <ul data-v-41055fb8="" class="list-unstyled">
                            <li data-v-41055fb8="">
                                <a data-v-41055fb8="" href="https://datos.gob.mx/">Portal de datos abiertos</a>
                            </li>
                            <li data-v-41055fb8="">
                                <a data-v-41055fb8="" href="https://www.gob.mx/accesibilidad">Declaración de
                                    accesibilidad</a>
                            </li>
                            <li data-v-41055fb8="">
                                <a data-v-41055fb8="" href="https://www.gob.mx/privacidadintegral">Aviso de privacidad
                                    integral</a>
                            </li>
                            <li data-v-41055fb8="">
                                <a data-v-41055fb8="" href="https://www.gob.mx/privacidadsimplificado">Aviso de
                                    privacidad
                                    simplificado</a>
                            </li>
                            <li data-v-41055fb8="">
                                <a data-v-41055fb8="" href="https://www.gob.mx/terminos">Términos y condiciones</a>
                            </li>
                            <li data-v-41055fb8="">
                                <a data-v-41055fb8=""
                                    href="https://www.gob.mx/terminos#medidas-seguridad-informacion">Política de
                                    seguridad</a>
                            </li>
                            <li data-v-41055fb8="">
                                <a data-v-41055fb8="" href="https://www.gob.mx/sitemap">Mapa del sitio</a>
                            </li>
                        </ul>
                    </div>
                    <div data-v-41055fb8="" class="col-sm-6 col-lg-3">
                        <h6 data-v-41055fb8="" align="left" id="division" data-fontsize="16" data-lineheight="19.2px"
                            class="fusion-responsive-typography-calculated "
                            style={{fontSize:"16", lineHeight: "1.2", minFontSize:"16"}}>
                        </h6>
                        <h6 data-v-41055fb8="" data-fontsize="16" data-lineheight="19.2px"
                            class="fusion-responsive-typography-calculated"
                            style={{fontSize:"16", lineHeight: "1.2", minFontSize:"16"}}>
                            <a data-v-41055fb8=""
                                href="https://www.gob.mx/tramites/ficha/presentacion-de-quejas-y-denuncias-en-la-sfp/SFP54"><u
                                    data-v-41055fb8="">Denuncia contra servidores públicos</u></a>
                        </h6>
                        <br data-v-41055fb8=""/>
                        <h6 data-v-41055fb8="" id="redes" data-fontsize="16" data-lineheight="19.2px"
                            class="fusion-responsive-typography-calculated mb-5"
                            style={{fontSize:"16", lineHeight: "1.2", minFontSize:"16"}}>
                            Síguenos en:</h6>
                        <ul data-v-41055fb8="" id="social" class="list-inline">
                            <li data-v-41055fb8="" class="list-inline-item">
                                <a data-v-41055fb8="" href="https://www.facebook.com/gobmexico/" target="_blank"
                                    red="Facebook" title="Enlace a facebook abre en una nueva ventana"
                                    class="sendEstFooterRs share-info">
                                    <img data-v-41055fb8="" alt="Facebook"
                                        src={facebook}/>
                                </a>
                            </li>
                            <li data-v-41055fb8="" class="list-inline-item">
                                <a data-v-41055fb8="" href="https://twitter.com/GobiernoMX" target="_blank"
                                    red="Twitter" title="Enlace a twitter abre en una nueva ventana"
                                    class="sendEstFooterRs share-info">
                                    <img data-v-41055fb8="" alt="Twitter"
                                        src={twitter}/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div data-v-41055fb8="" class="container-fluid footer-pleca"
                style={{backgroundImage: "url(https://framework-gb.cdn.gob.mx/landing/img/pleca.svg)"}}>
                <div data-v-41055fb8="" class="row">
                    <div data-v-41055fb8="" class="column"><br data-v-41055fb8=""/><br data-v-41055fb8=""/><br
                            data-v-41055fb8=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>
        
      </div>
    );

}